import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Files} from "@/components/globalModels/Files";
import _ from "lodash";
import {HEARTYSAN_STATUSES} from "@/staticData/staticVariables";
import {User} from "@/components/globalModels/User";


export function HeartysanListing() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    name: '',
    statusDefault: null,
    status: null,
    category: null,
    subCategory: null,
    description: null,
    price: null,
    country: null,
    delivery: null,
    quantity: null,
    Files: new Files({
      maxCountFiles: 5,
      maxSizeFiles: 3
    }),

    User: new User(),

    chats: [],
    manufacturerId: null,
    similarProducts: null,
    enable: null,
  }


  this.validation = {
    id: false,
    name: false,
    status: false,
    category: false,
    subCategory: false,
    description: false,
    price: false,
    country: false,
    delivery: false,
    quantity: false,
  }

  this.validationTranslate = {
    id: '',
    name: '',
    status: '',
    category: '',
    subCategory: '',
    description: '',
    price: '',
    country: '',
    delivery: '',
    quantity: '',
  }

  this.validationTxt = {
    id: false,
    name: false,
    status: false,
    category: false,
    subCategory: false,
    description: false,
    price: false,
    country: false,
    delivery: false,
    quantity: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getName = () => {
    return this.data.name
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getCategory = () => {
    return this.data.category
  }
  this.getSubCategory = () => {
    return this.data.subCategory
  }
  this.getDescription = () => {
    return this.data.description
  }
  this.getPrice = () => {
    return this.data.price
  }
  this.getCountry = () => {
    return this.data.country
  }
  this.getDelivery = () => {
    return this.data.delivery
  }
  this.getQuantity = () => {
    return this.data.quantity
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }

  this.setName = (val) => {
    this.data.name = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setCategory = (val) => {
    this.data.category = val
  }
  this.setSubCategory = (val) => {
    this.data.subCategory = val
  }
  this.setDescription = (val) => {
    this.data.description = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
  this.setCountry = (val) => {
    this.data.country = val
  }
  this.setDelivery = (val) => {
    this.data.delivery = val
  }
  this.setQuantity = (val) => {
    this.data.quantity = val
  }

}

/**
 * Global Setters
 */

HeartysanListing.prototype.setItem = function(val, categories) {
  this.data.User.setUser(val['user'])
  this.setId(val.id)
  this.setName(val.name)
  this.setStatus(_.find(HEARTYSAN_STATUSES, {value: val.status}))
  if(val?.category?.level !== 0) {
    this.setSubCategory(_.find(categories, {id: val.category.id}))
    if(val.category?.tree[0]?.id){
      this.setCategory(_.find(categories, {id: val.category?.tree[0]?.id}))
    }
  } else {
    this.setCategory(_.find(categories, {id: val.category.id}))
  }
  this.setDescription(val.description)
  this.setPrice(val.price)
  this.setCountry(val.country ? {
    id: val.country.id,
    label: val.country.name,
  } : null)
  this.setQuantity(val.production_terms)

  this.data.chats = val?.chats
  this.data.enable = val?.enable
  this.data.manufacturerId = val?.user_id
  this.data.similarProducts = val?.similar_products
  this.data.statusDefault = _.find(HEARTYSAN_STATUSES, {value: val.status})
}


/**
 * Prepare Data
 */

HeartysanListing.prototype.prepareSave = function(edit = false) {
  return {
    name: this.getName(),
    status: this.getStatus()?.value ? this.getStatus().value : null,
    category_id: this.getSubCategory()?.id ? this.getSubCategory()?.id : this.getCategory()?.id ? this.getCategory().id : null,
    description: this.getDescription(),
    price: this.getPrice(),
    country_id: this.getCountry()?.id ? this.getCountry().id : null,
    // delivery: this.getDelivery() ? '1' : '0',
    production_terms: this.getQuantity(),
    enable: edit ? this.data.enable : true
  }
}

HeartysanListing.prototype.setHeartysanListingDownloadFiles = function(val) {
  this.data.Files.pushDownloadFiles(val)
}

HeartysanListing.prototype.removeHeartysanListingFile = function(id) {
  let i = -1
  _.findIndex(this.data.Files.getDownloadFiles(), function (item, index) {
    if (item[id] !== undefined) i = index
  });

  this.data.Files.removeDownloadFile(i)
}


/**
 * Validations
 */

HeartysanListing.prototype.firstValidation = function() {
  let validationItems = {
    name: this.getName(),
    status: this.getStatus()?.value,
    category: this.getCategory()?.id,
    description: this.getDescription(),
    price: this.getPrice(),
    country: this.getCountry()?.id,
    quantity: this.getQuantity(),
  }

  let validationOptions = {
    name: {type: ['latin', 'empty']},
    status: {type: ['empty']},
    category: {type: ['empty']},
    description: {type: ['latin', 'empty']},
    price: {type: ['empty', 'not-zero']},
    country: {type: ['empty']},
    quantity: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}
