import {HEARTYSAN_STATUSES} from "@/staticData/staticVariables";

export const heartysanProductsHelperMixin = {

  methods: {
    getStatusByName(name) {
      return this._.find(HEARTYSAN_STATUSES, {name: name})
    },
  },

}



export const heartysanDownloadFileMixin = {

  methods: {
    downloadFileFromServer(file) {
      let idFile = file?.nested_file_entities[0]?.child_file_entry_id

      return this.$store.dispatch('getHeartysanFileFromServer', idFile)
        .then((response) => {
          let dataFile = response
          let fileBase64 = dataFile
          Object.keys(dataFile).map(item => {
            dataFile[item] = {
              id: item,
              type: file.mime_type,
              uuid: file.uuid,
              base64: fileBase64[item],
              typeSize: 'big_image'
            }
          })
          return dataFile
        })
    },
  },

}

export const heartysanProductsMixin = {

  mixins: [heartysanDownloadFileMixin],

  methods: {



    requestProduct(product) {
      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_yes',
        no: 'common_confirmNo'
      }

      this.$store.dispatch('fetchHeartysanChat', '')

      let confirm = () => {

        let data = {
          manufacturer_user_id: product?.user_id,
          seller_user_id: this.getCurrentUser?.id,
          hearty_product_id: product?.id,
          comment: '',
        }

        this.$store.dispatch('createHeartysanChat', data).then(response => {
          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('reload')
              this.openNotify('success', 'common_notificationRequestSended')

              this.sendFirstMessage(this.getRespData(response))
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

        // this.$store.dispatch('deleteHeartysanProducts', id).then((response) => {
        //
        //   if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
        //

        //
        // })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    sendFirstMessage(chat) {
      console.log(chat.id);
      let data = {
        'message': this.$t('heartysan_firstMessage.localization_value.value'),
      }

      let typeDispatch = 'createMessageHeartysanChat'

      this.$store.dispatch(typeDispatch, {data: data, id: chat.id}).then(response => {
        let resp = this.getRespData(response)
        this.$router.push(this.$store.getters.GET_PATHS.heartysanChat + `?chatId=${resp.chat.id}&productId=${resp.chat.product.id}`)
      })
    },

    save(edit = false) {
      if(!this.HL.firstValidation()) return

      let saveType = 'createHeartysanProducts'

      let data = this.HL.prepareSave(edit)

      data['user_id'] = this.getCurrentUser?.id

      if (edit) {
        saveType = 'updateHeartysanProducts'
        data = {
          data: data,
          id: this.HL.getId()
        }
      }

      this.$store.dispatch(saveType, data).then((response) => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if (this.HL.data.Files.getFiles().length > 0) {
              this.createFiles(this.getRespData(response).id).then(() => {
                this.$router.push(this.$store.getters.GET_PATHS.heartysanManufacturerListings)
                if(edit) {
                  this.openNotify('success', 'common_notificationRecordChanged')
                } else {
                  this.openNotify('success', 'common_notificationRecordCreated')
                }
              })
              return
            }

            if (edit) {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$router.push(this.$store.getters.GET_PATHS.heartysanManufacturerListings)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    createFiles(id) {
      let reader = new FormData();

      this.HL.data.Files.getFiles().map((item, index) => {
        if (!this._.has(item, 'maxCount') && !item.maxSizeError) {
          reader.append(`file${index + 1}`, item.file);
        }
      })
      reader.append("hearty_product_id", id);

      return this.$store.dispatch('addFilesHeartysanProduct', {data: reader}).then((response) => {

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      })
    },

  }
}
